{
	"$vuetify": {
		"badge": "Badge",
		"calendar": {
			"moreEvents": "{0} more"
		},
		"carousel": {
			"ariaLabel": {
				"delimiter": "Carousel slide {0} of {1}"
			},
			"next": "Next visual",
			"prev": "Previous visual"
		},
		"close": "Close",
		"confirmEdit": {
			"cancel": "Cancel",
			"ok": "OK"
		},
		"dataFooter": {
			"firstPage": "First page",
			"itemsPerPageAll": "All",
			"itemsPerPageText": "Items per page:",
			"lastPage": "Last page",
			"nextPage": "Next page",
			"pageText": "{0}-{1} of {2}",
			"prevPage": "Previous page"
		},
		"dataIterator": {
			"loadingText": "Loading items...",
			"noResultsText": "No matching records found"
		},
		"dataTable": {
			"ariaLabel": {
				"activateAscending": "Activate to sort ascending.",
				"activateDescending": "Activate to sort descending.",
				"activateNone": "Activate to remove sorting.",
				"sortAscending": "Sorted ascending.",
				"sortDescending": "Sorted descending.",
				"sortNone": "Not sorted."
			},
			"itemsPerPageText": "Rows per page:",
			"sortBy": "Sort by"
		},
		"datePicker": {
			"header": "Enter date",
			"input": {
				"placeholder": "Enter date"
			},
			"itemsSelected": "{0} selected",
			"range": {
				"header": "Enter dates",
				"title": "Select dates"
			},
			"title": "Select date"
		},
		"dateRangeInput": {
			"divider": "to"
		},
		"fileInput": {
			"counter": "{0} files",
			"counterSize": "{0} files ({1} in total)"
		},
		"infiniteScroll": {
			"empty": "No more",
			"loadMore": "Load more"
		},
		"input": {
			"appendAction": "{0} appended action",
			"clear": "Clear {0}",
			"otp": "Please enter OTP character {0}",
			"prependAction": "{0} prepended action"
		},
		"loading": "Loading...",
		"noDataText": "No data available",
		"open": "Open",
		"pagination": {
			"ariaLabel": {
				"currentPage": "Page {0}, Current page",
				"first": "First page",
				"last": "Last page",
				"next": "Next page",
				"page": "Go to page {0}",
				"previous": "Previous page",
				"root": "Pagination Navigation"
			}
		},
		"rating": {
			"ariaLabel": {
				"item": "Rating {0} of {1}"
			}
		},
		"stepper": {
			"next": "Next",
			"prev": "Previous"
		},
		"timePicker": {
			"am": "AM",
			"pm": "PM"
		}
	},
	"admin": {
		"title": "Administration",
		"users": {
			"add": {
				"activator": "Add a new user",
				"messages": {
					"error": "User {name} ({email}) could not be created.",
					"success": "User {name} ({email}) has been successfully created."
				},
				"title": "Add a new user"
			},
			"block": {
				"messages": {
					"error": "User {name} ({email}) could not be blocked.",
					"success": "User {name} ({email}) has been successfully blocked."
				}
			},
			"delete": {
				"confirm": "Delete user",
				"message": "Are you sure you want to delete user {name} ({email})?",
				"messages": {
					"error": "User {name} ({email}) could not be deleted.",
					"success": "User {name} ({email}) has been successfully deleted."
				},
				"title": "Delete user"
			},
			"edit": {
				"messages": {
					"error": "User {name} ({email}) could not be edited.",
					"success": "User {name} ({email}) has been successfully edited."
				},
				"title": "Edit user"
			},
			"invite": {
				"activator": "Invite a new user",
				"messages": {
					"error": "User {name} ({email}) could not be invited.",
					"success": "User {name} ({email}) has been successfully invited."
				},
				"title": "Invite a new user"
			},
			"list": {
				"title": "User management"
			},
			"resend": {
				"invitation": {
					"error": "Invitation to user {name} ({email}) could not be resent.",
					"success": "Invitation to user {name} ({email}) has been successfully resent."
				},
				"verification": {
					"error": "Verification link to user {name} ({email}) could not be resent.",
					"success": "Verification link to user {name} ({email}) has been successfully resent."
				}
			},
			"unblock": {
				"messages": {
					"error": "User {name} ({email}) could not be unblocked.",
					"success": "User {name} ({email}) has been successfully unblocked."
				}
			}
		}
	},
	"core": {
		"account": {
			"verification": {
				"messages": {
					"alreadyVerified": "Your account has already been verified.",
					"banned": "Your account has been banned.",
					"error": "Your account could not be verified.",
					"expired": "Your verification link has expired. New verification link has been sent to your email address.",
					"notFound": "Your verification link is invalid.",
					"success": "Your account has been successfully verified."
				},
				"redirect": "You will be redirected to the dashboard in {countdown} seconds.",
				"title": "Account verification"
			}
		},
		"actions": {
			"add": "Add",
			"cancel": "Cancel",
			"edit": "Edit",
			"invite": "Invite"
		},
		"devices": {
			"add": {
				"activator": "Add PDU",
				"messages": {
					"error": "PDU {name} could not be created.",
					"success": "PDU {name} has been successfully created."
				},
				"title": "Add a new PDU"
			},
			"delete": {
				"confirm": "Delete PDU",
				"message": "Are you sure you want to delete PDU {name} (MAC address {macAddress})?",
				"messages": {
					"error": "PDU {name} (MAC address {macAddress}) could not be deleted.",
					"success": "PDU {name} (MAC address {macAddress}) has been successfully deleted."
				},
				"title": "Delete PDU"
			},
			"detail": {
				"info": {
					"title": "Information about PDU"
				},
				"measurements": {
					"current": "Current",
					"messages": {
						"error": "Measurements could not be fetched."
					},
					"reload": "Reload",
					"timeRanges": {
						"12h": "Last 12 hours",
						"15m": "Last 15 minutes",
						"1d": "Last day",
						"1h": "Last hour",
						"1mo": "Last month",
						"1w": "Last week",
						"5m": "Last 5 minutes",
						"6h": "Last 6 hours"
					},
					"title": "Measurements",
					"voltage": "Voltage"
				},
				"messages": {
					"fetchFailed": "PDU {name} could not be fetched.",
					"notFound": "PDU {name} could not be found."
				},
				"outputs": {
					"messages": {
						"error": {
							"switchedOff": "Output #{index} ({name}) could not be switched off.",
							"switchedOn": "Output #{index} ({name}) could not be switched on."
						},
						"success": {
							"switchedOff": "Output #{index} ({name}) has been successfully switched off.",
							"switchedOn": "Output #{index} ({name}) has been successfully switched on."
						}
					},
					"reload": "Reload",
					"title": "Outputs"
				},
				"title": "PDU - {name}"
			},
			"edit": {
				"activator": "Edit PDU",
				"messages": {
					"error": "PDU {name} could not be edited.",
					"success": "PDU {name} has been successfully edited."
				},
				"title": "Edit PDU"
			},
			"fields": {
				"lastSeen": "Last seen",
				"macAddress": "MAC address",
				"measurements": {
					"current": "Current",
					"voltage": "Voltage"
				},
				"name": "Name",
				"outputs": {
					"alert": "Alert",
					"alertValues": {
						"false": "No",
						"true": "Yes"
					},
					"index": "Index",
					"name": "Name",
					"state": "State",
					"title": "Outputs"
				}
			},
			"form": {
				"messages": {
					"emptyMacAddress": "Please enter a MAC address for the PDU.",
					"emptyName": "Please enter a name for the PDU.",
					"outputs": {
						"emptyIndex": "Please enter an index for the output.",
						"emptyName": "Please enter a name for the output."
					}
				},
				"outputs": {
					"title": "Outputs"
				}
			},
			"list": {
				"title": "PDU list"
			},
			"title": "Power distribution units"
		},
		"error": {
			"403": {
				"header": "Error 403",
				"message": "You do not have permission to view this page. Please try contact the web site administrator if you believe you should be able to view this page.",
				"title": "Access denied"
			},
			"404": {
				"header": "Error 404",
				"message": "The page you requested could not be found. It is possible that the address is incorrect, or that the page no longer exists. Please use a search engine to find what you are looking for.",
				"title": "Page not found"
			}
		},
		"locales": {
			"cs": "Czech",
			"en": "English"
		},
		"messages": {
			"localeChanged": "Localization has been successfully changed to {lang}."
		},
		"openApi": {
			"messages": {
				"error": "An error occurred during API documentation loading."
			},
			"title": "API documentation"
		},
		"password": {
			"recovery": {
				"button": "Recover password",
				"messages": {
					"error": "An error occurred during password recovery request.",
					"success": "Password recovery request has been successfully sent."
				},
				"text": "Please enter your e-mail address and a password recovery email will be sent to the specified e-mail address",
				"title": "Password recovery"
			},
			"reset": {
				"button": "Reset password",
				"messages": {
					"banned": "Your account has been banned.",
					"error": "An error occurred during password reset.",
					"expired": "Password reset request has expired.",
					"notFound": "Password reset request could not be found.",
					"success": "Password has been successfully reset."
				},
				"text": "Please enter your new password.",
				"title": "Password reset"
			},
			"set": {
				"button": "Set password",
				"messages": {
					"banned": "Your account has been banned.",
					"error": "An error occurred during password set.",
					"expired": "Password set request has expired.",
					"notFound": "Password set request could not be found.",
					"success": "Password has been successfully set."
				},
				"text": "Please enter your new password.",
				"title": "Set password"
			}
		},
		"profile": {
			"fields": {
				"passwordChange": "Do you want to change your password?"
			},
			"messages": {
				"error": "User profile could not be edited.",
				"incorrectCurrentPassword": "Current password is incorrect.",
				"success": "User profile has been successfully edited."
			},
			"title": "User profile"
		},
		"session": {
			"expiration": {
				"countdown": "Your session expired. | Your session will expire in {countdown} second. | Your session will expire in {countdown} seconds.",
				"extend": "Extend Session",
				"message": "To continue working in the application, click on the \"Extend Session\" button.",
				"messages": {
					"error": "An error occurred during session extension.",
					"signOut": "Your session has expired. Please sign in again.",
					"success": "Your session has been successfully extended."
				},
				"title": "Your session will expire soon"
			}
		},
		"sign": {
			"in": {
				"button": "Sign in",
				"messages": {
					"accountBlocked": "Your account has been blocked.",
					"error": "An error occurred while signing in.",
					"invalidCredentials": "Invalid e-mail or password.",
					"success": "You have been successfully signed in."
				},
				"title": "Sign in"
			},
			"out": {
				"message": "You have been successfully signed out.",
				"title": "Sign out"
			}
		},
		"tables": {
			"actions": "Actions"
		},
		"title": "SBC PDU management",
		"user": {
			"fields": {
				"avatar": "Avatar",
				"email": "E-mail address",
				"language": "Language",
				"name": "Name",
				"newPassword": "New password",
				"oldPassword": "Current password",
				"password": "Password",
				"role": "Role",
				"state": "State"
			},
			"messages": {
				"emptyEmail": "Please enter e-mail address.",
				"emptyLanguage": "Please select user language.",
				"emptyName": "Please enter user name.",
				"emptyNewPassword": "Please enter new password.",
				"emptyOldPassword": "Please enter current password.",
				"emptyPassword": "Please enter password.",
				"emptyRole": "Please select user role.",
				"invalidEmail": "Please enter valid e-mail address."
			},
			"roles": {
				"admin": "Administrator",
				"normal": "Normal user"
			},
			"states": {
				"blocked": "Blocked",
				"invited": "Invited",
				"unverified": "Unverified",
				"verified": "Verified"
			},
			"totp": {
				"add": {
					"activator": "Add TOTP authenticator",
					"confirm": "Add authenticator",
					"messages": {
						"error": "TOTP authenticator {name} could not be added.",
						"success": "TOTP authenticator {name} has been successfully added."
					},
					"title": "Add a new TOTP authenticator"
				},
				"delete": {
					"confirm": "Delete authenticator",
					"message": "Are you sure you want to delete TOTP authenticator {name}?",
					"messages": {
						"error": "TOTP authenticator {name} could not be deleted.",
						"success": "TOTP authenticator {name} has been successfully deleted."
					},
					"title": "Delete TOTP authenticator"
				},
				"fields": {
					"code": "Code",
					"createdAt": "Created at",
					"lastUsedAt": "Last used at",
					"name": "Name"
				},
				"lastUsedAts": {
					"never": "Never"
				},
				"messages": {
					"emptyCode": "Please enter code.",
					"emptyName": "Please enter authenticator name.",
					"incorrectCode": "Code is incorrect.",
					"invalidCode": "Please enter valid code.",
					"prompt": "Please enter the code from your authenticator app."
				},
				"secret": {
					"copy": {
						"error": "Secret key could not be copied to clipboard.",
						"success": "Secret key has been successfully copied to clipboard."
					},
					"formats": {
						"qrCode": "QR code",
						"text": "Text"
					},
					"label": "Secret key",
					"openApp": "Open TOTP authenticator app"
				},
				"title": "Two-factor authentication"
			},
			"verification": {
				"resend": {
					"button": "Resend verification e-mail",
					"messages": {
						"error": "An error occurred during sending verification e-mail.",
						"success": "Verification e-mail has been successfully sent."
					},
					"text": "Your e-mail address is not verified. Some features (e.g. password recovery) may not be available."
				}
			}
		}
	}
}
