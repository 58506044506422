{
	"$vuetify": {
		"badge": "Odznak",
		"calendar": {
			"moreEvents": "{0} dalších"
		},
		"carousel": {
			"ariaLabel": {
				"delimiter": "Obrázek {0} z {1}"
			},
			"next": "Další obrázek",
			"prev": "Předchozí obrázek"
		},
		"close": "Zavřít",
		"confirmEdit": {
			"cancel": "Zrušit",
			"ok": "OK"
		},
		"dataFooter": {
			"firstPage": "První strana",
			"itemsPerPageAll": "Vše",
			"itemsPerPageText": "Položek na stránku:",
			"lastPage": "Poslední strana",
			"nextPage": "Další strana",
			"pageText": "{0}-{1} z {2}",
			"prevPage": "Předchozí strana"
		},
		"dataIterator": {
			"loadingText": "Načítám položky...",
			"noResultsText": "Nenalezeny žádné záznamy"
		},
		"dataTable": {
			"ariaLabel": {
				"activateAscending": "Aktivováním se bude řadit vzestupně.",
				"activateDescending": "Aktivováním se bude řadit sestupně.",
				"activateNone": "Aktivováním vypnete řazení.",
				"sortAscending": "Řazeno vzestupně.",
				"sortDescending": "Řazeno sestupně.",
				"sortNone": "Neseřazeno."
			},
			"itemsPerPageText": "Řádků na stránku:",
			"sortBy": "Řadit dle"
		},
		"datePicker": {
			"header": "Zadejte datum",
			"input": {
				"placeholder": "Zadejte datum"
			},
			"itemsSelected": "{0} vybrán",
			"range": {
				"header": "Zadejte datumy",
				"title": "Vyberte datumy"
			},
			"title": "Vyberte datum"
		},
		"dateRangeInput": {
			"divider": "do"
		},
		"fileInput": {
			"counter": "{0} souborů",
			"counterSize": "{0} souborů ({1} celkem)"
		},
		"infiniteScroll": {
			"empty": "Žádné další",
			"loadMore": "Načíst více"
		},
		"input": {
			"appendAction": "{0} appended action",
			"clear": "Vymazat {0}",
			"otp": "Vložte výhradně OTP znaky {0}",
			"prependAction": "{0} prepended action"
		},
		"loading": "Načítám...",
		"noDataText": "Nejsou dostupná žádná data",
		"open": "Otevřit",
		"pagination": {
			"ariaLabel": {
				"currentPage": "Aktuální stránka, stránka {0}",
				"first": "První stránka",
				"last": "Poslední stránka",
				"next": "Další strana",
				"page": "Přejít na stránku {0}",
				"previous": "Předchozí strana",
				"root": "Navigace po stránkách"
			}
		},
		"rating": {
			"ariaLabel": {
				"item": "Hodnocení {0} z {1}"
			}
		},
		"stepper": {
			"next": "Další",
			"prev": "Předchozí"
		},
		"timePicker": {
			"am": "odp.",
			"pm": "dop."
		}
	},
	"admin": {
		"title": "Administrace",
		"users": {
			"add": {
				"activator": "Přidat uživatele",
				"messages": {
					"error": "Uživatele {name} ({email}) se nepodařilo přidat.",
					"success": "Uživatel {name} ({email}) byl úspěšně přidán."
				},
				"title": "Přidání nového uživatele"
			},
			"block": {
				"messages": {
					"error": "Uživatele {name} ({email}) se nepodařilo zablokovat.",
					"success": "Uživatel {name} ({email}) byl úspěšně zablokován."
				}
			},
			"delete": {
				"confirm": "Smazat uživatele",
				"message": "Chcete opravdu odstranit uživatele {name} ({email})?",
				"messages": {
					"error": "Uživatele {name} ({email}) se nepodařilo odstranit.",
					"success": "Uživatel {name} ({email}) byl úspěšně odstraněn."
				},
				"title": "Odstranění uživatele"
			},
			"edit": {
				"messages": {
					"error": "Uživatele {name} ({email}) se nepodařilo upravit.",
					"success": "Uživatel {name} ({email}) byl úspěšně upraven."
				},
				"title": "Úprava uživatele"
			},
			"invite": {
				"activator": "Pozvat uživatele",
				"messages": {
					"error": "Uživatele {name} ({email}) se nepodařilo pozvat.",
					"success": "Uživatel {name} ({email}) byl úspěšně pozván."
				},
				"title": "Pozvání nového uživatele"
			},
			"list": {
				"title": "Správa uživatelů"
			},
			"resend": {
				"invitation": {
					"error": "Pozvánku pro uživatele {name} ({email}) se nepodařilo odeslat.",
					"success": "Pozvánka pro uživatele {name} ({email}) byla úspěšně odeslána."
				},
				"verification": {
					"error": "Ověřovací email pro uživatele {name} ({email}) se nepodařilo odeslat.",
					"success": "Ověřovací email pro uživatele {name} ({email}) byl úspěšně odeslán."
				}
			},
			"unblock": {
				"messages": {
					"error": "Uživatele {name} ({email}) se nepodařilo odblokovat.",
					"success": "Uživatel {name} ({email}) byl úspěšně odblokován."
				}
			}
		}
	},
	"core": {
		"account": {
			"verification": {
				"messages": {
					"alreadyVerified": "Váš účet již byl ověřen.",
					"banned": "Váš účet byl zablokován.",
					"error": "Ověření Vašeho účtu se nezdařilo.",
					"expired": "Ověřovací kód již vypršel. Nový kód Vám byl odeslán na Váši e-mailovou adresu.",
					"notFound": "Ověřovací kód nebyl nalezen.",
					"success": "Váš účet byl úspěšně ověřen."
				},
				"redirect": "Za {countdown} sekund budete přesměrováni na úvodní stránku.",
				"title": "Oveření uživatelského účtu"
			}
		},
		"actions": {
			"add": "Přidat",
			"cancel": "Zrušit",
			"edit": "Upravit",
			"invite": "Pozvat"
		},
		"devices": {
			"add": {
				"activator": "Přidat jednotku",
				"messages": {
					"error": "Napájecí jednotku {name} se nepodařilo přidat.",
					"success": "Napájecí jednotka {name} byla úspěšně přidána."
				},
				"title": "Přidání nové napájecí jednotky"
			},
			"delete": {
				"confirm": "Smazat napájecí jednotku",
				"message": "Chcete opravdu odstranit napájecí jednotku {name} (MAC adresa {macAddress})?",
				"messages": {
					"error": "Napájecí jednotku {name} (MAC adresa {macAddress}) se nepodařilo odstranit.",
					"success": "Napájecí jednotka {name} (MAC adresa {macAddress}) byla úspěšně odstraněna."
				},
				"title": "Odstranění napájecí jednotky"
			},
			"detail": {
				"info": {
					"title": "Informace o napájecí jednotce"
				},
				"measurements": {
					"current": "Elektrický proud",
					"messages": {
						"error": "Nepodařilo se načíst měřené veličiny."
					},
					"reload": "Obnovit",
					"timeRanges": {
						"12h": "Posledních 12 hodin",
						"15m": "Posledních 15 minut",
						"1d": "Poslední den",
						"1h": "Poslední hodina",
						"1mo": "Poslední měsíc",
						"1w": "Poslední týden",
						"5m": "Posledních 5 minut",
						"6h": "Poslední 6 hodin"
					},
					"title": "Grafy měřených veličin",
					"voltage": "Elektrické napětí"
				},
				"messages": {
					"fetchFailed": "Nepodařilo se načíst informace o napájecí jednotce {name}.",
					"notFound": "Napájecí jednotka {name} nebyla nalezena."
				},
				"outputs": {
					"messages": {
						"error": {
							"switchedOff": "Výstup #{index} ({name}) se nepodařilo vypnout.",
							"switchedOn": "Výstup #{index} ({name}) se nepodařilo zapnout."
						},
						"success": {
							"switchedOff": "Výstup #{index} ({name}) byl vypnut.",
							"switchedOn": "Výstup #{index} ({name}) byl zapnut."
						}
					},
					"reload": "Obnovit",
					"title": "Výstupy napájecí jednotky"
				},
				"title": "Detail napájecí jednotky - {name}"
			},
			"edit": {
				"activator": "Upravit jednotku",
				"messages": {
					"error": "Napájecí jednotku {name} se nepodařilo upravit.",
					"success": "Napájecí jednotka {name} byla úspěšně upravena."
				},
				"title": "Úprava napájecí jednotky"
			},
			"fields": {
				"lastSeen": "Naposledy viděno",
				"macAddress": "MAC adresa",
				"measurements": {
					"current": "El. proud",
					"voltage": "El. napětí"
				},
				"name": "Název",
				"outputs": {
					"alert": "Alarm",
					"alertValues": {
						"false": "Ne",
						"true": "Ano"
					},
					"index": "Index",
					"name": "Název",
					"state": "Stav",
					"title": "Výstupy"
				}
			},
			"form": {
				"messages": {
					"emptyMacAddress": "Prosím zadejte MAC adresu napájecí jednotky.",
					"emptyName": "Prosím zadejte jméno napájecí jednotky.",
					"outputs": {
						"emptyIndex": "Prosím zadejte index výstupu.",
						"emptyName": "Prosím zadejte jméno výstupu."
					}
				},
				"outputs": {
					"title": "Výstupy"
				}
			},
			"list": {
				"title": "Seznam napájecích jednotek"
			},
			"title": "Napájecí jednotky"
		},
		"error": {
			"403": {
				"header": "Chyba 403",
				"message": "K zobrazení této stránky nemáte oprávnění. Pokud se domníváte, že byste měli mít možnost tuto stránku zobrazit, zkuste prosím kontaktovat správce webu.",
				"title": "Přístup zamítnut"
			},
			"404": {
				"header": "Chyba 404",
				"message": "Požadovanou stránku se nepodařilo najít. Je možné, že adresa je nesprávná nebo že stránka již neexistuje. K nalezení hledané stránky použijte vyhledávač.",
				"title": "Stránka nenalezena"
			}
		},
		"locales": {
			"cs": "Čestina",
			"en": "Angličtina"
		},
		"messages": {
			"localeChanged": "Jazyk byl úspěšně změněn na {lang}."
		},
		"openApi": {
			"messages": {
				"error": "Při načítání dokumentace API nastala chyba."
			},
			"title": "API dokumentace"
		},
		"password": {
			"recovery": {
				"button": "Obnovit heslo",
				"messages": {
					"error": "Při odeslání požadavku pro obnovu hesla nastala chyba.",
					"success": "Požadavek pro obnovu hesla byl úspěšně odeslán"
				},
				"text": "Pro obnovu zapomenutého hesla zadejte Vaši e-mailovou adresu, na kterou zašleme potvrzovací e-mail s odkazem pro nastavení nového hesla.",
				"title": "Obnova hesla"
			},
			"reset": {
				"button": "Nastavit nové heslo",
				"messages": {
					"banned": "Váš účet byl zablokován.",
					"error": "Při nastavování hesla nastala chyba.",
					"expired": "Požadavek pro obnovu hesla již vypršel.",
					"notFound": "Požadavek pro obnovu hesla nebyl nalezen.",
					"success": "Heslo bylo úspěšně nastaveno."
				},
				"text": "Zadejte prosím nové heslo pro Váš účet.",
				"title": "Nastavení nového hesla"
			},
			"set": {
				"button": "Nastavit heslo",
				"messages": {
					"banned": "Váš účet byl zablokován.",
					"error": "Při nastavování hesla nastala chyba.",
					"expired": "Požadavek pro nastavení hesla již vypršel.",
					"notFound": "Požadavek pro nastavení hesla nebyl nalezen.",
					"success": "Heslo bylo úspěšně nastaveno."
				},
				"text": "Zadejte prosím heslo pro Váš účet.",
				"title": "Nastavení hesla"
			}
		},
		"profile": {
			"fields": {
				"passwordChange": "Chcete změnit vaše heslo?"
			},
			"messages": {
				"error": "Uživatelský profil se nepodařilo aktualizovat.",
				"incorrectCurrentPassword": "Stávající heslo není správné.",
				"success": "Uživatelský profil byl úspěšně aktualizován."
			},
			"title": "Uživatelský profil"
		},
		"session": {
			"expiration": {
				"countdown": "Vaše relace vypršela. | Vaše relace vyprší za {countdown} sekundu. | Vaše relace vyprší za {countdown} sekundy. | Vaše relace vyprší za {countdown} sekund.",
				"extend": "Prodloužit relaci",
				"message": "Chcete-li pokračovat v práci v aplikaci, klikněte na tlačítko „Prodloužit relaci“.",
				"messages": {
					"error": "Při prodlužování Vaší relace nastala chyba.",
					"signOut": "Vaše relace vypršela. Byl jste odhlášen.",
					"success": "Vaše relace byla prodloužena."
				},
				"title": "Brzy vyprší platnost Vaší relace"
			}
		},
		"sign": {
			"in": {
				"button": "Přihlásit se",
				"messages": {
					"accountBlocked": "Váš účet je zablokovaný.",
					"error": "Přihlášení se nezdařilo.",
					"invalidCredentials": "Neplatné přihlašovací údaje.",
					"success": "Byl jste úspěšně přihlášen."
				},
				"title": "Přihlášení"
			},
			"out": {
				"message": "Byl jste úspěšně odhlášen.",
				"title": "Odhlásit se"
			}
		},
		"tables": {
			"actions": "Akce"
		},
		"title": "Správce napájecích jednotek",
		"user": {
			"fields": {
				"avatar": "Avatar",
				"email": "E-mailová adresa",
				"language": "Jazyk",
				"name": "Jméno",
				"newPassword": "Nové heslo",
				"oldPassword": "Stávající heslo",
				"password": "Heslo",
				"role": "Role",
				"state": "Stav"
			},
			"messages": {
				"emptyEmail": "Zadejte prosím e-mailovou adresu.",
				"emptyLanguage": "Vyberte prosím jazyk.",
				"emptyName": "Zadejte prosím jméno.",
				"emptyNewPassword": "Zadejte prosím nové heslo.",
				"emptyOldPassword": "Zadejte prosím stávající heslo.",
				"emptyPassword": "Zadejte prosím heslo.",
				"emptyRole": "Vyberte prosím roli.",
				"invalidEmail": "Zadejte prosím platnou e-mailovou adresu."
			},
			"roles": {
				"admin": "Administrátor",
				"normal": "Běžný uživatel"
			},
			"states": {
				"blocked": "Zablokovaný",
				"invited": "Pozvaný",
				"unverified": "Neověřený",
				"verified": "Ověřený"
			},
			"totp": {
				"add": {
					"activator": "Přidat TOTP authentikátor",
					"confirm": "Přidat TOTP authentikátor",
					"messages": {
						"error": "TOTP authenticator {name} se nepodařilo přidat.",
						"success": "TOTP authenticator {name} byl úspěšně přidán."
					},
					"title": "Přidání TOTP authentikátoru"
				},
				"delete": {
					"confirm": "Odstranit authentikátor",
					"message": "Opravdu chcete odstranit TOTP authenticator {name}?",
					"messages": {
						"error": "TOTP authenticator {name} se nepodařilo odstranit.",
						"success": "TOTP authenticator {name} byl úspěšně odstraněn."
					},
					"title": "Odstranění TOTP authentikátoru"
				},
				"fields": {
					"code": "Kód",
					"createdAt": "Vytvořeno",
					"lastUsedAt": "Naposledy použito",
					"name": "Název"
				},
				"lastUsedAts": {
					"never": "Nikdy"
				},
				"list": {
					"loadFailed": "Nepodařilo se načíst seznam TOTP authentikátorů."
				},
				"messages": {
					"emptyCode": "Zadejte prosím kód.",
					"emptyName": "Zadejte prosím název authentikátoru.",
					"incorrectCode": "Zadaný kód není správný.",
					"invalidCode": "Zadejte prosím platný kód.",
					"prompt": "Prosím zadejte kód z authentizační aplikace."
				},
				"secret": {
					"copy": {
						"error": "Při kopírování soukromého klíče nastala chyba.",
						"success": "Soukromý klíč byl úspěšně zkopírován do schránky."
					},
					"formats": {
						"qrCode": "QR kód",
						"text": "Text"
					},
					"label": "Soukromý klíč",
					"openApp": "Otevřít aplikaci authentikátoru"
				},
				"title": "Dvoufaktorová autentikace"
			},
			"verification": {
				"resend": {
					"button": "Znovu odeslat ověřovací e-mail",
					"messages": {
						"error": "Při odesílání ověřovacího e-mailu nastala chyba.",
						"success": "Ověřovací e-mail byl úspěšně odeslán."
					},
					"text": "Vaše e-mailová adresa není oveřena, některé akce (např. obnova hesla) proto nemusí být dostupné."
				}
			}
		}
	}
}
